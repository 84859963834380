import React, { Component } from 'react'
import Layout from '../components/layout/layout'

import ServicesDetail from '../components/Services/ServicesDetail'

export default class MarketResearch extends Component {
  render() {
    const stanford = require('../assets/images/stanford.jpeg')
    const serviceImage = require('../assets/images/rawpixel-574844-unsplash.jpg')
    const majapa = require('../assets/images/majapa.png')

    return (
      <Layout location={this.props.location}>
        <ServicesDetail
          service="Market Research"
          serviceCaption="In depth Market insight to understand the markets you operate in"
          serviceDocumentLink="https://drive.google.com/file/d/14qbE2GgNBJtB1nSC02uqkoK9n_jt142D/view?usp=sharing"
          serviceHighlight={[
            'Brand tracking',
            'Sentiment analysis',
            'Net promoter Scoring',
          ]}
          serviceDocumentLink="https://drive.google.com/file/d/14ktdRLmYCbH8MVA9GaavZbV3i0SI6tk7/view?usp=sharing"
          serviceTopDescription="At Bora Growth Partners, we help our clients maximize their performance by helping them understand the markets they operate in. Power is gained by sharing knowledge, not hoarding it."
          serviceBottomDescription="We conduct market research, due diligence, planning and execution services for successful entry into new markets across a spectrum of industries with a key focus in Sub Saharan Africa operational expansion"
          serviceImage={serviceImage}
          serviceSpecialists={[
            {
              name: 'STANFORD CHEELO MUJUTA',
              title: 'Economist and Market Research',
              linkedin:
                'https://za.linkedin.com/in/stanford-cheelo-mujuta-b17a2255',
              twitter: 'https://twitter.com/BoraGrowth',
              image: stanford,
            },
            {
              name: 'Mohamed Majapa',
              title: 'Managing partner, Co-founder',
              linkedin: 'https://www.linkedin.com/in/mohamed-majapa-2a93a2b2/',
              twitter: 'https://twitter.com/Ti_Mj',
              image: majapa,
            },
          ]}
        />
      </Layout>
    )
  }
}
